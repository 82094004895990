import styles from '@styles/common-scss/card/_videoCard.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import aa from 'search-insights';

interface SiteProps {
    reviewID: number;
    reviewName: string;
    reviewSlug: string;
    partnerID: string;
    videoTitle?: string;
    tourlink?: string;
    videoQuality?: string;
    image?: string;
    video?: string;
    category?: string;
    categorySlug?: string;
    duration?: string;
    datePublished?: string;
    sceneNumber?: number;
    subsiteID?: number;
    subsiteName?: string;
    subsiteSlug?: string;
    isUpcoming?: boolean;
    stars?: {
        name: string;
        slug?: string;
        id?: string;
    }[];
}

interface VideoCardProps {
    site?: SiteProps;
    index?: number;
    pageTag?: string;
    eventSender?: (eventType: 'click' | 'conversion', eventData: any, eventName: string) => void;
    eventData?: any;
}

const PrepareLink = React.memo(({ text, slug, type, router }: { text: string; slug: string; type: string; router: any }) => {
    if (!slug) {
        return <div className={styles.right}>{text}</div>;
    }

    let href = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/reviews/${slug.toLocaleLowerCase()}`;

    if (type === 'subsites') {
        if (router.pathname.includes('videos')) {
            if (String(router.query.id).toLowerCase() === String(slug).toLowerCase()) {
                return null;
            }
        }
        href += '/videos';
    } else if (type === 'categories') {
        href = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn${slug.toLocaleLowerCase()}`;
    }

    return (
        <Link href={href} passHref>
            <a className={styles.right} title={`link to ${text} sites`}>
                {text}
            </a>
        </Link>
    );
});
PrepareLink.displayName = 'PrepareLink';

export default function VideoCard({ site, index = 0, pageTag, eventSender, eventData }: VideoCardProps): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [imgSrc, setImgSrc] = useState(site.image);

    const router = useRouter();
    const observedThumb = useRef<HTMLDivElement>(null);
    const relCategory = 'nofollow noreferrer';

    useEffect(() => {
        setLoading(false);
    }, []);

    // This function finds out the correct event to be sent to Algolia.
    function createAlgoliaEvent(site: SiteProps, eventType: 'click' | 'conversion', eventName: string): void {
        if (eventSender && eventData) {
            const sessionStorageData = {
                queryID: eventData.__queryID,
                reviewSlug: site.reviewID
            };
            sessionStorage.setItem('algoliaQuery', JSON.stringify(sessionStorageData));
            eventSender(eventType, eventData, eventName);
        } else {
            aa('init', {
                appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
                apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD,
                useCookie: true, // since v2, this is false by default
                cookieDuration: 60 * 60 * 1000 // one hour in milliseconds
            });
            const algoliaType = eventType === 'click' ? 'clickedObjectIDs' : 'convertedObjectIDs';
            let sortValue = '';
            if (router.pathname.includes('/porn/categories/') || router.pathname.includes('/porn/tag/')) {
                sortValue = router.query?.sort
                    ? ` ${String(router.query.sort).charAt(0).toUpperCase() + String(router.query.sort).slice(1)}`
                    : ' Relevance';
            }
            aa(algoliaType, {
                index: 'RRX',
                eventName: `${eventName}${sortValue}${router.asPath ? ` ${router.asPath}` : ''}`.slice(0, 64),
                objectIDs: [`${site.reviewID}`]
            });
        }
    }

    const thumbDefault: string | undefined = undefined;
    const siteLinkDefault: string | undefined = undefined;

    const thumbLogger = (
        inView: boolean,
        thumbID: string | undefined,
        thumb: string | undefined,
        siteLink: string | undefined
    ): void => {
        if (inView === true && !loading) {
            const resolvedThumb = thumb ?? 'il';
            const resolvedSiteLink = siteLink ?? 'p';
            const resolvedThumbID = thumbID ?? '0';
            try {
                const lpath = `${process.env.NEXT_PUBLIC_API_DOMAIN}/v1/pthumb/${resolvedThumbID}`;
                fetch(lpath, {
                    method: 'POST'
                }).catch((e) => {
                    // do nothing
                });
            } catch (err) {
                // do nothing
            }
        }
    };

    useEffect(() => {
        if (!site.isUpcoming && !document.querySelector('#first-newest-card')) {
            observedThumb.current?.setAttribute('id', 'first-newest-card');
        }
    }, [site?.isUpcoming]);

    return (
        <>
            <div className={styles.container} ref={observedThumb}>
                {/* Thumbnail */}
                <div className={styles.head}>
                    <div className={styles.image}>
                        <a
                            href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/OpenSite.aspx?SiteID=${site.reviewID}&type=video`}
                            rel={relCategory}
                            target="_blank"
                            title={`Visit ${site.reviewName}`}
                            data-opensite-action={`${site.reviewName}-thumb`}
                            data-opensite-category="Opensite"
                            data-opensite-path={`outgoing,${site.reviewName},thumb`}
                            data-opensite-site={`${site.reviewName} (${site.reviewID})`}
                            onClick={() => createAlgoliaEvent(site, 'conversion', 'Card Thumbnail')}>
                            <InView
                                triggerOnce
                                onChange={(inView: boolean) => {
                                    thumbLogger(inView, String(site.reviewID), thumbDefault, siteLinkDefault);
                                }}>
                                <Image
                                    unoptimized
                                    alt="Site thumbnail"
                                    src={imgSrc}
                                    onError={() => {
                                        setImgSrc('https://assets.rabbitsreviews.com/images/rabbitslogo.png');
                                    }}
                                    layout="fill"
                                    objectFit="cover"
                                    data-thumbid={site.videoTitle}
                                    priority={index < 3}
                                    className={styles.image}
                                />
                            </InView>
                        </a>
                        {site.videoQuality.toLowerCase() !== 'n/a' && <div className={styles.label}>{site.videoQuality}</div>}
                        <PrepareLink text={site.category} slug={site.categorySlug} type={'categories'} router={router} />
                    </div>
                </div>
                {/* Thumbnail End */}
                <div className={styles.content}>
                    <div className={`${styles.text} ${styles.corner}`}>
                        <h3>
                            <Link
                                href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/OpenSite.aspx?SiteID=${site.reviewID}&type=video`}
                                passHref>
                                <a
                                    className={styles.title}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-opensite-action={`${site.reviewName}-videoTitle`}
                                    data-opensite-category="Opensite"
                                    data-opensite-site={`${site.reviewName} (${site.reviewID})`}>
                                    {site.videoTitle}
                                </a>
                            </Link>
                        </h3>
                        <div className={styles.rating}>
                            {site.duration && site.duration !== '0' && site.duration.toLowerCase() !== 'n/a'
                                ? `${site.duration}mins`
                                : ''}
                        </div>
                    </div>
                    <div className={`${styles.text} ${styles.corner}`}>
                        <div className={styles.subtitle}>
                            <PrepareLink
                                text={router.pathname.includes('reviews') ? site.subsiteName : site.reviewName}
                                slug={router.pathname.includes('reviews') ? site.subsiteSlug : site.reviewSlug}
                                type={router.pathname.includes('reviews') ? 'subsites' : 'reviews'}
                                router={router}
                            />
                        </div>
                        <div className={`${styles.date} ${site.isUpcoming ? styles.green : styles.white}`}>
                            {site.datePublished}
                        </div>
                    </div>
                    <div className={styles.features}>
                        {site.stars.map((star, idx) => {
                            if (star.slug) {
                                if (star.slug.toLowerCase() === 'videocards') {
                                    return <span key={idx}>{star.name}</span>;
                                } else {
                                    return (
                                        <Link
                                            key={idx}
                                            href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/stars/${star.id}/${star.slug}`}>
                                            <a href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/stars/${star.id}/${star.slug}`}>
                                                <span className={styles.active}>{star.name}</span>
                                            </a>
                                        </Link>
                                    );
                                }
                            } else {
                                return (
                                    <span className={styles.inactive} key={idx}>
                                        {star.name}
                                    </span>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
